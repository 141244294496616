import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [2];

export const dictionary = {
		"/(authenticated)": [~3,[2]],
		"/(authenticated)/applications": [~4,[2]],
		"/delete-account": [~18],
		"/delete-guest-account": [19],
		"/(authenticated)/entry-types": [~5,[2]],
		"/(authenticated)/entry-types/[entryTypeId]": [~6,[2]],
		"/(authenticated)/events": [~7,[2]],
		"/(authenticated)/events/[clubEventId]": [~8,[2]],
		"/(authenticated)/groups": [~9,[2]],
		"/(authenticated)/groups/[groupId]": [~10,[2]],
		"/(authenticated)/guests": [~11,[2]],
		"/(authenticated)/guests/[guestId]": [~12,[2]],
		"/login": [~20],
		"/(authenticated)/logout": [13,[2]],
		"/(authenticated)/push-notifications": [~14,[2]],
		"/(authenticated)/settings": [~15,[2]],
		"/(authenticated)/staff": [~16,[2]],
		"/(authenticated)/staff/[staffId]": [~17,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';