import { env } from '$env/dynamic/public';
import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://cf9e8254f80d4a0d9ee461fa7cbdd0e5@o1243581.ingest.us.sentry.io/4506732584763392',
	tracesSampleRate: 0.0,

	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	integrations: [replayIntegration()],
	enabled: env.PUBLIC_ENVIRONMENT === 'production'
});

export const handleError = handleErrorWithSentry();
